<template>
  <div class="game-container">
    <RocketGame :bcgImg="bcgImg" />
  </div>
</template>

<script>
import RocketGame from "@/components/Course/GamePage/RocketGame";
export default {
  data() {
    return {
      bcgImg: require("@/assets/img/16-GAMES/G09-rocket/background-space-lesson-10.svg"),
    };
  },
  components: {
    RocketGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>