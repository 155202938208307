<template>
  <div class="game-container layout-border">
    <FindStrokeTrain
      :targetStrokeImg="targetStrokeImg"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :strokePinyin="strokePinyin"
      @showStroke="handleShowStroke"
      :shadowImg="shadowImg"
      :headerBgImg="headerBgImg"
    />
  </div>
</template>

<script>
import FindStrokeTrain from "@/components/Course/GamePage/FindStrokeTrainGame";
export default {
  data() {
    return {
      headerBgImg: {
        defaultImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/0-bulb-on.svg"),
        firstImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/1-bulb-on.svg"),
        secondImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/2-bulb-on.svg"),
        thirdImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/3-bulb-on.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/cloud.svg"),
      targetStrokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/stroke-dian-white.svg"),
      strokePinyin: "diǎn",
      gameIndex: 5,
      lightIndex: [1, 4, 8],
      hanziStrokeInfo: [
        {
          pinyin: "zhǔ",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/zhu-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/zhu-1.svg"),
              isShow: false,
              index: 1,
            },
          ],
        },
        {
          pinyin: "tóu",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/tou-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/tou-1.svg"),
              isShow: false,
              index: 2,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/tou-2.svg"),
              isShow: false,
              index: 3,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/tou-3.svg"),
              isShow: false,
              index: 4,
            },
          ],
        },
        {
          pinyin: "diǎn",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/dian-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/dian-1.svg"),
              isShow: false,
              index: 5,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/dian-2.svg"),
              isShow: false,
              index: 6,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/dian-3.svg"),
              isShow: false,
              index: 7,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-1/dian-4.svg"),
              isShow: false,
              index: 8,
            },
          ],
        },
      ],
    };
  },
  components: {
    FindStrokeTrain,
  },
  methods: {
    handleShowStroke(index, indexSon) {
      this.hanziStrokeInfo[index].strokeFinishImgList[indexSon].isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>