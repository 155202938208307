<template>
  <div class="game-container layout-border">
    <FindStrokeTrain
      :targetStrokeImg="targetStrokeImg"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :strokePinyin="strokePinyin"
      @showStroke="handleShowStroke"
      :shadowImg="shadowImg"
      :headerBgImg="headerBgImg"
    />
  </div>
</template>

<script>
import FindStrokeTrain from "@/components/Course/GamePage/FindStrokeTrainGame";
export default {
  data() {
    return {
      headerBgImg: {
        defaultImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/penguin-1.svg"),
        firstImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/penguin-2.svg"),
        secondImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/penguin-3.svg"),
        thirdImg:require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/penguin-4.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-06-1/ice-cube.svg"),
      targetStrokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/stroke-hengpie-white.svg"),
      strokePinyin: "héngpiě",
      gameIndex: 6,
      lightIndex: [1, 3, 6],
      hanziStrokeInfo: [
        {
          pinyin: "yòu",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/you-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/you-1.svg"),
              isShow: false,
              index: 1,
            },
          ],
        },
        {
          pinyin: "duō",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/duo-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/duo-1.svg"),
              isShow: false,
              index: 2,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/duo-2.svg"),
              isShow: false,
              index: 3,
            },
          ],
        },
        {
          pinyin: "sāng",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/sang-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/sang-1.svg"),
              isShow: false,
              index: 4,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/sang-2.svg"),
              isShow: false,
              index: 5,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-10-2/sang-3.svg"),
              isShow: false,
              index: 6,
            },
          ],
        },
      ],
    };
  },
  components: {
    FindStrokeTrain,
  },
  methods: {
    handleShowStroke(index, indexSon) {
      this.hanziStrokeInfo[index].strokeFinishImgList[indexSon].isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>