<template>
  <div class="game-container">
    <ChooseWordsByPicGame
      :trumpetImg="trumpetImg"
      :chooseHanziList="chooseHanziList"
      :buttonList="buttonList"
    />
  </div>
</template>

<script>
import ChooseWordsByPicGame from "@/components/Course/GamePage/ChooseWordsByPicGame";
export default {
  data() {
    return {
      buttonList: [
        {
          image: require("@/assets/img/01-Menu/menu-1.svg"),
          startNumberRange: 0,
          endNumberRange: 0,
        },
        {
          image: require("@/assets/img/01-Menu/menu-2.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/01-Menu/menu-3.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/01-Menu/menu-4.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/01-Menu/menu-5.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
      trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-1.svg`),
          wordsPinyin: "Wǒ ài nǐ.",
          wordsHanzi: "我爱你。",
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },

            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
            {
              pinyin: "nǐ",
              hanzi: "你",
              id: 1,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "nǐ",
              hanzi: "你",
              id: 1,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },

            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-2.svg`),
          wordsPinyin: "Wǒ ài lǎoshī.",
          wordsHanzi: "我爱老师。",
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },

            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
            {
              pinyin: "lǎoshī",
              hanzi: "老师",
              id: 1,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "lǎoshī",
              hanzi: "老师",
              id: 1,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },

            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-3.svg`),
          wordsPinyin: "Wǒ ài māma.",
          wordsHanzi: "我爱妈妈。",
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },

            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
            {
              pinyin: "māma",
              hanzi: "妈妈",
              id: 1,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "māma",
              hanzi: "妈妈",
              id: 1,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },

            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-4.svg`),
          wordsPinyin: "Wǒ ài bàba.",
          wordsHanzi: "我爱爸爸。",
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
            {
              pinyin: "bàba",
              hanzi: "爸爸",
              id: 1,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 3,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 2,
            },
            {
              pinyin: "bàba",
              hanzi: "爸爸",
              id: 1,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G31-make-sentences/lesson-10/story-5.svg`),
          wordsPinyin: "Wǒ ài wǒ jiā.",
          wordsHanzi: "我爱我家。",
          hanziRightList: [
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 1,
            },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 2,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 1,
            },
            {
              pinyin: "jiā",
              hanzi: "家",
              id: 3,
            },
          ],
          hanziChooseList: [
            {
              pinyin: "jiā",
              hanzi: "家",
              id: 3,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 1,
            },
            {
              pinyin: "ài",
              hanzi: "爱",
              id: 2,
            },
            {
              pinyin: "wǒ",
              hanzi: "我",
              id: 1,
            },
          ],
        },
      ],
    };
  },
  components: {
    ChooseWordsByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
