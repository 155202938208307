<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/background-seabed.svg"),
      gameIndex: 7,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-jellyfish.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-jellyfish-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-mermaid.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-mermaid-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-squid.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-squid-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-starfish.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-starfish-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-tortoise.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-10-2/stroke-tortoise-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/stroke-hengpie.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/stroke-hengpie-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
